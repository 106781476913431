.intro {
  background-image: url(../../assets/images/intro-bg.webp);
  background-repeat: no-repeat;
  background-position: 120% 0%;

  @media #{$xl-dw} {
    background-position: 140% 0%;
  }

  @media #{$lg-dw} {
    background-image: none;
  }
}
