.benefits {
  &__card {
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    background-color: color('white');
    transition: transition(all);

    &:hover {
      border-color: color('primary');
    }
  }
}
