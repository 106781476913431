.footer {
  &__link {
    display: inline-block;
    color: color('muted');

    &:hover {
      color: color('primary');
    }
  }
}
