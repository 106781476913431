// ============================== //
// ===== Scrollbar Settings ===== //
// ============================== //

/// Here you can custmize default scrollbar view
/// @link https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar

::-webkit-scrollbar             { width: 8px }
::-webkit-scrollbar-track       { background: white }
::-webkit-scrollbar-thumb       { background: lighten(black, 15%) }
::-webkit-scrollbar-thumb:hover { background: black }
