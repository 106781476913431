.badge {
  padding: spacer(1, 3);
  margin-bottom: spacer(4);
  border-style: solid;
  border-width: 1px;
  border-radius: radius('small');
  border-color: color('primary');
  font-weight: 500;
  color: color('primary');
}
