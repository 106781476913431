/// This code generates opacity classnames
/// Opacity values are stored in $opacity map
/// This classnames supports breakpoints from $breakpoints map
/// 
/// @author nightrunner91
/// 
/// @link https://github.com/nightrunner91/nightpack/#opacity
/// @link https://developer.mozilla.org/ru/docs/Web/CSS/opacity
/// 
/// @output
///   .opacity-0 { opacity: 0 }
///   .opacity-25 { opacity: 0.25 }
///   .opacity-50 { opacity: 0.5 }
///   .opacity-75 { opacity: 0.75 }
///   .opacity-100 { opacity: 1 }
///
///   @media screen and (min-width: 1280px) {
///     .opacity-lg-50 {
///        opacity: 0.5
///     }
///   }

@mixin make-opacity($breakpoint:null) {
  @each $opacity, $value in $opacities {
    .opacity#{$breakpoint}-#{$opacity} { opacity: opacity($opacity) !important }
  }
}

@include make-opacity();

@each $breakpoint, $size in $breakpoints {
  @media screen and (min-width: #{$size}) {
    @include make-opacity('-' + $breakpoint)
  }
};
