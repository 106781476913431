/// This code generates classnames with specific border-radius value
/// Each classname supports $breakpoints
/// 
/// @author nightrunner91
/// 
/// @link https://github.com/nightrunner91/nightpack/#radius
/// @link https://developer.mozilla.org/ru/docs/Web/CSS/border-radius
///
/// @output
///   .radius-small { border-radius: 4px }
///   .radius-tl-small { border-top-left-radius: 4px }
///   .radius-t-small {
///     border-top-left-radius: 4px;
///     border-top-right-radius: 4px;
///   }
///
///   @media screen and (min-width: 768px) {
///     .radius-b-sm-large {
///        border-bottom-left-radius: 20px;
///        border-bottom-right-radius: 20px;
///      }
///   }

@mixin make-radius($breakpoint:null) {
  @each $name, $size in $radiuses {
    .radius#{$breakpoint}-#{$name} { border-radius: $size !important }

    .radius-tl#{$breakpoint}-#{$name} { border-top-left-radius: $size !important }
    .radius-tr#{$breakpoint}-#{$name} { border-top-right-radius: $size !important }
    .radius-bl#{$breakpoint}-#{$name} { border-bottom-left-radius: $size !important }
    .radius-br#{$breakpoint}-#{$name} { border-bottom-right-radius: $size !important }

    .radius-t#{$breakpoint}-#{$name} {
      border-top-left-radius: $size !important;
      border-top-right-radius: $size !important;
    }
    .radius-r#{$breakpoint}-#{$name} {
      border-top-right-radius: $size !important;
      border-bottom-right-radius: $size !important;
    }
    .radius-b#{$breakpoint}-#{$name} {
      border-bottom-left-radius: $size !important;
      border-bottom-right-radius: $size !important;
    }
    .radius-l#{$breakpoint}-#{$name} {
      border-top-left-radius: $size !important;
      border-bottom-left-radius: $size !important;
    }
  }
}

@include make-radius();

@each $breakpoint, $size in $breakpoints {
  @media screen and (min-width: #{$size}) {
    @include make-radius('-' + $breakpoint);
  }
}
