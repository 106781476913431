$button-height-sm: 42px;
$button-height-md: 48px;
$button-height-lg: 60px;

.button {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  border-width: 1px;
  border-style: solid;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
  transition: transition(all);
  user-select: none;

  // sizes
  &--sm {
    height: $button-height-sm;
    padding: spacer(0, 4);
    border-radius: $button-height-sm / 2;
  }
  &--md {
    height: $button-height-md;
    padding: spacer(0, 6);
    border-radius: $button-height-md / 2;
  }
  &--lg {
    height: $button-height-lg;
    padding: spacer(0, 10);
    border-radius: $button-height-lg / 2;
  }

  // variants
  &--outline-dark {
    border-color: color('dark');
    color: color('dark');

    &:hover {
      border-color: color('primary');
      color: color('primary');
    }
  }

  &--primary {
    background-color: color('primary');
    border-color: color('primary');
    color: color('white');

    &:hover {
      background-color: color('dark');
      border-color: color('dark');
      color: color('white');
    }
  }

  &--light {
    background-color: color('white');
    border-color: color('white');
    color: color('dark');

    &:hover {
      background-color: color('dark');
      border-color: color('dark');
      color: color('white');
    }
  }

  &--link {
    background-color: transparent;
    border-color: transparent;
    color: color('dark');

    &:hover {
      color: color('primary');
    }
  }
}
