/// This code generates classnames for shadows stored in $shadows map
/// By default this map contains list of shadows based on Material methodology
/// 
/// @author nightrunner91
/// 
/// @link https://github.com/nightrunner91/nightpack/#shadows
/// @link https://developer.mozilla.org/ru/docs/Web/CSS/box-shadow
/// @link https://material.io/design/environment/light-shadows.html
/// @link https://material.io/design/environment/elevation.html
///
/// @output
///   .shadow-level-1 { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) }
///   .shadow-level-2 { box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) }
///   .shadow-level-3 { box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) }
///   .shadow-level-4 { box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) }
///   .shadow-level-5 { box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22) }

@each $shadow, $value in $shadows {
  .shadow-#{$shadow} { box-shadow: $value !important; }
}
