/// This code generates classnames with specific sizes in %, vw and vh
/// Sizing values are stored in $sizings map
/// %	is relative to the parent element
/// vw is relative to 1% of the width of the viewport
/// vh is relative to 1% of the height of the viewport
/// Each classname supports $breakpoints
/// 
/// @author nightrunner91
/// 
/// @link https://github.com/nightrunner91/nightpack/#sizing
/// @link https://developer.mozilla.org/ru/docs/Web/CSS/length
/// @link https://developer.mozilla.org/ru/docs/Web/CSS/width
/// @link https://developer.mozilla.org/ru/docs/Web/CSS/height
///
/// @output
///   .w-auto { width: auto }
///   .h-0 { height: 0% }
///
///   .w-75 { width: 75% }
///   .vw-75 { width: 75 }
///   .max-w-75 { max-width: 75% }
///   .max-vw-75 { max-width: 75 }
///
///   .h-25 { height: 25% }
///   .vh-25 { height: 25vh }
///   .min-h-25 { min-height: 25% }
///   .min-vh-25 { min-height: 25vh }
///
///   @media screen and (min-width: 768px) {
///     .min-w-sm-50 {
///        min-width: 50%;
///      }
///   }

@mixin make-sizing($breakpoint:null) {
  @each $name, $sizing in $sizings {
    @if $name != 'auto' {
      .w#{$breakpoint}-#{$name} { width: unquote($sizing + '%') !important }
      .max-w#{$breakpoint}-#{$name} { max-width: unquote($sizing + '%') !important }
      .min-w#{$breakpoint}-#{$name} { min-width: unquote($sizing + '%') !important }
      .h#{$breakpoint}-#{$name} { height: unquote($sizing + '%') !important }
      .max-h#{$breakpoint}-#{$name} { max-height: unquote($sizing + '%') !important }
      .min-h#{$breakpoint}-#{$name} { min-height: unquote($sizing + '%') !important }

      .vw#{$breakpoint}-#{$name} { width: unquote($sizing + 'vw') !important }
      .max-vw#{$breakpoint}-#{$name} { max-width: unquote($sizing + 'vw') !important }
      .min-vw#{$breakpoint}-#{$name} { min-width: unquote($sizing + 'vw') !important }
      .vh#{$breakpoint}-#{$name} { height: unquote($sizing + 'vh') !important }
      .max-vh#{$breakpoint}-#{$name} { max-height: unquote($sizing + 'vh') !important }
      .min-vh#{$breakpoint}-#{$name} { min-height: unquote($sizing + 'vh') !important }
    } @else {
      .w#{$breakpoint}-#{$name} { width: $sizing !important }
      .max-w#{$breakpoint}-#{$name} { max-width: $sizing !important }
      .min-w#{$breakpoint}-#{$name} { min-width: $sizing !important }
      .h#{$breakpoint}-#{$name} { height: $sizing !important }
      .max-h#{$breakpoint}-#{$name} { max-height: $sizing !important }
      .min-h#{$breakpoint}-#{$name} { min-height: $sizing !important }
    }
  }
}

@include make-sizing();

@each $breakpoint, $size in $breakpoints {
  @media screen and (min-width: #{$size}) {
    @include make-sizing('-' + $breakpoint)
  }
};
