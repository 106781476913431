$header-height: 68px;
$header-height-lg: 90px;

.header {
  height: $header-height;

  @media #{$lg-up} {
    height: $header-height-lg;
  }
}

.main {
  padding-top: $header-height;

  @media #{$lg-up} {
    padding-top: $header-height-lg;
  }
}

.menu, .mmenu {
  &__link {
    display: inline-block;
    white-space: nowrap;
    color: color('dark');
    font-weight: 500;

    &:hover {
      color: color('primary');
    }
  }
}

.mmenu {
  top: $header-height;
  height: calc(100% - #{$header-height});
  transform: translateX(-100%);
  transition: transition(transform);

  &--opened {
    transform: translateX(0);
  }
}
