/// In this section are collected small helpers which did't find a place in the sections listed above
/// 
/// @author nightrunner91
/// 
/// @link https://github.com/nightrunner91/nightpack/#utilities

$cursors: pointer, help, wait, move, not-allowed, context-menu, alias;

@each $cursor in $cursors {
  .cursor-#{$cursor} { cursor: $cursor !important }
}

.no-select { user-select: none !important }
.no-overflow { overflow: hidden !important }
.no-events  { pointer-events: none !important }
.visible { visibility: visible !important }
.invisible { visibility: hidden !important }
